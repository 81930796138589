import React, { Component } from "react";
import { API } from "aws-amplify";
import { Link } from "react-router-dom";
import { LinkContainer } from "react-router-bootstrap";
import { PageHeader, ListGroup, ListGroupItem } from "react-bootstrap";
import "./Home.css";

export default class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      blogs: []
    };
  }

  async componentDidMount() {
    if (!this.props.isAuthenticated) {
      return;
    }

    try {
      const blogs = await this.blogs();
      this.setState({ blogs });
    } catch (e) {
      alert(e);
    }

    this.setState({ isLoading: false });
  }

  blogs() {
    return API.get("blogs", "/blogs");
  }

  renderBlogsList(blogs) {
    return [{}].concat(blogs).map(
      (blog, i) =>
        i !== 0
          ? <LinkContainer
              key={blog.blogId}
              to={`/blogs/${blog.blogId}`}
            >
              <ListGroupItem header={(blog.title||blog.content||'').trim().split("\n")[0]}>
                {"Created: " + new Date(blog.createdAt).toLocaleString()}
              </ListGroupItem>
            </LinkContainer>
          : <LinkContainer
              key="new"
              to="/blogs/new"
            >
              <ListGroupItem>
                <h4>
                  <b>{"\uFF0B"}</b> Create a new blog
                </h4>
              </ListGroupItem>
            </LinkContainer>
    );
  }

  renderLander() {
    return (
      <div className="lander">
        <h1>Welcome to Blog RPG!</h1>
        <p>A place to b-log your adventures!</p>
        <div>
          <Link to="/login" className="btn btn-info btn-lg">
            Login
          </Link>
          <Link to="/signup" className="btn btn-success btn-lg">
            Signup
          </Link>
        </div>
      </div>
    );
  }

  renderBlogs() {
    return (
      <div className="blogs">
        <PageHeader>Your Campaigns</PageHeader>
        <ListGroup>
          {!this.state.isLoading && this.renderBlogsList(this.state.blogs)}
        </ListGroup>
      </div>
    );
  }

  render() {
    return (
      <div className="Home">
        {this.props.isAuthenticated ? this.renderBlogs() : this.renderLander()}
      </div>
    );
  }
}
