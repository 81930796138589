import React, { Component } from "react";
import { API } from "aws-amplify";
import { FormGroup, FormControl } from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";
import { s3Upload } from "../libs/awsLib";
import config from "../config";
import "./NewBlog.css";

export default class NewBlog extends Component {
  constructor(props) {
    super(props);

    this.file = null;

    this.state = {
      isLoading: null,
      title: "",
      content: ''
    };
  }

  createBlog(blog) {
    return API.post("blogs", "/blogs", {
      body: blog
    });
  }

  validateForm() {
    return this.state.title.length > 0 && this.state.content.length>0;
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  }

  handleFileChange = event => {
    this.file = event.target.files[0];
  }

  handleSubmit = async event => {
    event.preventDefault();

    if (this.file && this.file.size > config.MAX_ATTACHMENT_SIZE) {
      alert(`Please pick a file smaller than ${config.MAX_ATTACHMENT_SIZE/1000000} MB.`);
      return;
    }

    this.setState({ isLoading: true });

    try {
      const attachment = this.file
        ? await s3Upload(this.file)
        : null;

      await this.createBlog({
        attachment, 
        title: this.state.title, 
        content: this.state.content
      });
      this.props.history.push("/");
    } catch (e) {
      alert(e);
      this.setState({ isLoading: false });
    }
  }

  render() {
    return <div className="NewBlog">
        <form onSubmit={this.handleSubmit}>
          <FormGroup controlId="title">
            <FormControl onChange={this.handleChange} value={this.state.title} type="text" />
          </FormGroup>
          <FormGroup controlId="content">
            <FormControl onChange={this.handleChange} value={this.state.content} componentClass="textarea" />
          </FormGroup>
          <LoaderButton block bsStyle="primary" bsSize="large" disabled={!this.validateForm()} type="submit" isLoading={this.state.isLoading} text="Create" loadingText="Creating…" />
        </form>
      </div>;
  }
}
